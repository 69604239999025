import logo from "assets/images/brands/brand-main-sm.png";

export const clientName = "Markets Act";
export const developedBy = "Markets Act";

export const imagesUrl = process.env.REACT_APP_API_IMAGES_URL || "http://localhost:3001/static/";
export const cpUrl = process.env.REACT_APP_CP_URL || "http://localhost:4002/";
export const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3001/";
export const companyName =  "Markets Act";
export const sidebarLogo = logo;
export const mainLogo = logo;
export const lightLogo = logo;